import React, { useState } from "react"
import { Link } from "gatsby"

import arrow from "./arrow.svg"

export function NavItem(props) {
  const [clicked, setClicked] = useState(false)
  return (
    <li
      key={props.to}
      onClick={() => setClicked(true)}
      onMouseLeave={() => setClicked(false)}
    >
      <Link
        className={clicked ? "navItem clicked " : "navItem "}
        activeClassName="active"
        to={props.to}
      >
        <span className="black">
          <img className="arrow" src={arrow} alt="" />
          <span className="line" />
        </span>
        {props.name}
      </Link>
    </li>
  )
}

export default function Nav() {
  return (
    <nav className="futura">
      <ul>
        <NavItem name="Intro" to="/" />
        <NavItem name="Brand" to="/brand" />
        <NavItem name="Wall" to="/wall" />
        <NavItem name="About" to="/about" />
      </ul>
    </nav>
  )
}
