import React from "react"
import { Helmet } from "react-helmet"
import Nav from "./src/components/nav"
import Footer from "./src/components/footer"

export const wrapRootElement = ({ element }) => (
  <>
    <Helmet>
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=UA-140688798-1"
      />
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'UA-140688798-1');
        `}
      </script>
    </Helmet>

    <Nav />
    {element}
    <Footer />
  </>
)
